import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ArmyBuilderConfig, Modal, ModalComponent, PurchasingService, SettingsService } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { SubscriptionService } from './subscription.service';
import { Router } from '@angular/router';
import { PaywallComponent } from './paywall.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'wl-manage-subscription',
    template: `
        <ng-container
            *ngLet="{
                hasExpired: hasExpired$ | async,
                user: user$ | async,
                expiry: expiry$ | async,
                expiryProgress: expiryProgress$ | async,
                expiryProgressColour: expiryProgressColour$ | async,
                newCustomer: newCustomer$ | async,
                monthlySubscription: monthlySubscription$ | async
            } as props"
        >
            @if (!props.newCustomer) {
                @if (props.hasExpired) {
                    <ion-card color="danger">
                        <ion-card-content> Your subscription expired {{ props.expiry | relativeDate }}. </ion-card-content>
                    </ion-card>
                } @else {
                    <ion-progress-bar [color]="props.expiryProgressColour" [value]="props.expiryProgress"></ion-progress-bar>
                    <p>Your subscription will expire {{ props.expiry | relativeDate }}.</p>
                }

                @if (props.user.roles.includes('app_subscriber_annual')) {
                    <p>Subscription tier: annual.</p>
                    <p>If not cancelled, your subscription will auto-renew on {{ props.expiry }} at £21.89.</p>
                    <ng-template #voucherDetails>
                        <voucher-details></voucher-details>
                    </ng-template>
                    <ion-button *ngIf="showVoucher()" class="fullWidth" (click)="showVoucherDetails()">Warlord Discount Voucher</ion-button>
                } @else if (props.user.roles.includes('app_subscriber')) {
                    <p>Subscription tier: monthly.</p>
                    <p>
                        If not cancelled, your subscription will auto-renew on {{ props.expiry }} at
                        {{ props.monthlySubscription?.displayPrice }}.
                    </p>
                    @if (web) {
                        <p>Upgrade your membership to receive exclusive discounts and early access to new content.</p>
                        <ion-button class="fullWidth" (click)="subscribe()">Upgrade to Annual Membership</ion-button>
                        <br /><br />
                    }
                }
            } @else {
                <ion-button class="fullWidth" (click)="subscribe()">Subscribe</ion-button>
            }

            <form action="{{ config.apiBaseUrl }}/purchase/createPortalSession" method="POST" *ngIf="web">
                <input type="hidden" name="accessToken" value="{{ props.user?.accessToken }}" />
                <ion-button class="fullWidth" type="submit">Manage Subscription</ion-button>
                <br /><br />
            </form>

            <p>
                If you have purchased a subscription and it is not reflected in the app, try the button below to manually refresh the
                subscription status. If that doesn't work, send an email to
                <a href="mailto:warlord@maloric.com">warlord&#64;maloric.com</a> and we'll do whatever we can to assist.
            </p>

            <ion-button class="fullWidth" (click)="refreshPurchases()">Refresh Subscription </ion-button>

            <!-- <ion-button (click)="checkSubscription()">Check Subscription</ion-button> -->
            <ng-container *ngIf="!stripeCheckout">
                <div *ngIf="subscription$ | async as product" class="buttons">
                    <ion-button color="danger" *ngIf="product.type === 'PAID_SUBSCRIPTION' && isOwned(product.key)" (click)="cancelSub()">
                        {{ 'GLOBAL.SUBSCRIPTION.CANCEL' | translate }}
                    </ion-button>
                </div>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            .content {
                margin-bottom: 0;
            }
        `
    ]
})
export class ManageSubscriptionComponent {
    hasExpired$ = this.subscriptionService.hasExpired$;
    newCustomer$ = this.subscriptionService.newCustomer$;
    stripeCheckout = !(this.platform.is('android') || this.platform.is('ios'));
    subscription$ = this.purchasingService.products$.pipe(map((p) => p[0]));
    status$: Observable<string[]> = this.purchasingService.status$;
    user$ = this.settingsService.login$.pipe(map((l) => l.user));
    expiry$ = this.subscriptionService.expiry$;
    expiryProgress$ = this.expiry$.pipe(
        map((e) => {
            const daysRemaining = Math.floor((e.getTime() - Date.now()) / 1000 / 60 / 60 / 24);
            return daysRemaining / 28;
        })
    );

    showVoucher = toSignal(this.user$.pipe(map((u) => u?.roles.includes('app_subscriber_annual'))));

    expiryProgressColour$ = this.expiryProgress$.pipe(
        map((e) => {
            if (e > 0.5) {
                return 'success';
            }
            if (e > 0.25) {
                return 'warning';
            }
            return 'danger';
        })
    );

    web = !this.platform.is('cordova');
    monthlySubscription$ = this.purchasingService.products$.pipe(
        map((products) => {
            return products.find((p) => p.key === 'app_sub');
        })
    );

    @ViewChild('voucherDetails')
    voucherDetails: TemplateRef<any>;

    constructor(
        public config: ArmyBuilderConfig,
        private subscriptionService: SubscriptionService,
        private platform: Platform,
        private purchasingService: PurchasingService,
        private modal: Modal,
        private settingsService: SettingsService,
        private router: Router
    ) {}

    purchase(productId: string) {
        this.purchasingService.purchase(productId);
    }

    subscribe() {
        this.modal.show({
            component: PaywallComponent
        });
    }

    isOwned(productId: string) {
        return this.purchasingService.isOwned(productId);
    }

    async cancelSub() {
        this.modal.show({
            component: CancelSubscriptionComponent
        });
    }

    refreshPurchases() {
        this.purchasingService.refreshUserPurchases();
        this.router.navigateByUrl('/subscription/handle-purchase');
    }

    async showVoucherDetails() {
        this.modal.show({
            component: ModalComponent,
            props: {
                title: 'Voucher Redemption',
                contentTemplate: this.voucherDetails,
                cssClass: 'home'
            }
        });
    }
}
