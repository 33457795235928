import { NgModule } from '@angular/core';

import { EntityPreloadComponent } from './entityPreload.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';
import { StoreModule } from '@ngrx/store';
import { EntityPreloadModalComponent } from './entityPreload-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { getTranslateModuleConfig } from '../i18n';

@NgModule({
    imports: [CommonModule, IonicModule, NgLetModule, StoreModule, TranslateModule.forChild(getTranslateModuleConfig('global'))],
    exports: [EntityPreloadComponent, EntityPreloadModalComponent],
    declarations: [EntityPreloadComponent, EntityPreloadModalComponent],
    providers: []
})
export class EntityPreloadModule {}
