import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLibraryData } from 'army-builder-shared';
import { debounceTime, BehaviorSubject } from 'rxjs';
import { EntityLibraryState, EntityLibraryLoadStatus, ENTITY_LIBRARY_ACTIONS } from '../army-builder-entity-store';

@Component({
    selector: 'abs-entity-preload',
    template: `
        <ion-list>
            <ion-item *ngFor="let entityKey of entityTypeKeys">
                Loading {{ 'GLOBAL.ENTITY.' + gameId + '.' + entityKey | translate }}
                @if (entityDataLoadingState[entityKey] | async; as entityStatus) {
                    <ion-spinner *ngIf="entityStatus === 'LOADING'" [slot]="'end'"></ion-spinner>
                    <ion-icon *ngIf="entityStatus === 'LOADED'" name="checkmark-circle-outline" [slot]="'end'"></ion-icon>

                    <ng-container *ngIf="entityStatus === 'ERROR'">
                        <ion-button [size]="'small'" [slot]="'end'" (click)="retry.emit({ gameId, entityKey })">Retry</ion-button>
                        <ion-icon name="close-circle-outline" [slot]="'end'"></ion-icon>
                    </ng-container>
                } @else {
                    <ion-spinner [slot]="'end'"></ion-spinner>
                }
            </ion-item>
        </ion-list>

        <div class="buttons" *ngIf="erroredKeys.length > 0">
            <ion-button (click)="retryAll()">Retry all <ion-icon name="refresh" [slot]="'end'"></ion-icon></ion-button>
            <ion-button (click)="cancel()">Cancel</ion-button>
        </div>
        <div class="buttons" *ngIf="erroredKeys.length === 0">
            <ion-button (click)="close()">Close</ion-button>
        </div>
    `,

    styles: [
        `
            ion-list {
                overflow-y: auto;
                display: block;
                max-height: 65vh;
            }
        `
    ]
})
export class EntityPreloadComponent {
    @Input()
    gameId: string;

    @Input()
    entityTypeKeys: string[];

    @Output()
    retry = new EventEmitter<{ gameId: string; entityKey: string }>();

    entityDataLoadingState: {
        [key: string]: BehaviorSubject<EntityLibraryLoadStatus>;
    } = {};

    erroredKeys: string[] = [];

    sub = this.store
        .select(getLibraryData)
        .pipe(debounceTime(100))
        .subscribe((lib: EntityLibraryState) => {
            if (!lib?.data || !this.entityTypeKeys) {
                return null;
            }

            let gameEntities = lib.data[this.gameId];
            if (!gameEntities) {
                console.error('Missing entity cache for gameId: ' + this.gameId);
                return null;
            }
            for (let key of this.entityTypeKeys) {
                const status = gameEntities[key].status;
                if (!this.entityDataLoadingState[key]) {
                    this.entityDataLoadingState[key] = new BehaviorSubject<EntityLibraryLoadStatus>(status);
                } else {
                    this.entityDataLoadingState[key].next(status);
                }
            }

            this.erroredKeys = Object.keys(gameEntities).filter((key) => gameEntities[key].status === 'ERROR');
        });

    constructor(private store: Store) {}

    retryAll() {
        for (let key of this.erroredKeys) {
            this.retry.emit({ gameId: this.gameId, entityKey: key });
        }
    }

    cancel() {
        this.store.dispatch(ENTITY_LIBRARY_ACTIONS.HIDE_ENTITY_PRELOAD_MODAL());
        for (let key of this.entityTypeKeys) {
            this.store.dispatch(ENTITY_LIBRARY_ACTIONS.UPDATE_LIBRARY_STATUS({ gameId: this.gameId, dataType: key, status: 'LOADED' }));
        }
    }

    close() {
        this.store.dispatch(ENTITY_LIBRARY_ACTIONS.HIDE_ENTITY_PRELOAD_MODAL());
    }
}
