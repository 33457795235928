import { Injectable, signal } from '@angular/core';
import { RouterService } from 'army-builder-shared';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    theme$ = new BehaviorSubject('');
    theme = signal('');

    constructor(private routerService: RouterService) {}

    selectTheme(name: string) {
        this.theme$.next(name);
        this.theme.set(name);
    }
}
