import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';

import { AlertService, DataLibrary, getUniqueUnitId, LANGUAGE, Modal, PurchasingService, SettingsService, snapshot } from '../../../global';
import { ArmyBuilderConfig } from '../../../global/config';
import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Unit, UnitTemplate } from '../../models';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'abs-add-unit',
    styles: [
        `
            .itemName {
                flex-direction: column;
                align-items: start;
                justify-content: space-around;
            }
            .subType {
                font-style: italic;
                font-size: 0.9em;
            }
        `
    ],
    template: `
        <abs-page-layout *ngIf="force$ | async as force" [title]="'FORCES.BUTTONS.ADD_UNIT' | translate">
            <ng-template #unitTemplate let-item="item">
                <div class="itemName">
                    <span>{{ item.name | language | async }}</span>
                    <span class="subType">{{ item.subType }}</span>
                </div>
                <div class="itemCost">{{ item.baseCost }}{{ 'GLOBAL.PTS' | translate }}</div>
            </ng-template>

            <abs-list
                [items]="units$ | async"
                [showDelete]="false"
                (itemSelect)="addUnit($event.item, {})"
                [template]="unitTemplate"
                groupBy="type"
            ></abs-list>
        </abs-page-layout>
    `
})
export class AddUnitPage {
    public forceId$ = this.forceService.forceId$;
    public force$ = this.forceService.force$;
    public force = toSignal(this.force$);

    public units$ = combineLatest([this.route.data, this.force$]).pipe(
        switchMap(([routeData, force]) =>
            this.dataLibrary.unitTemplates$.pipe(
                map((unitTemplates) => {
                    if (!routeData.filterUnitsByFaction) {
                        return unitTemplates;
                    }
                    return unitTemplates.filter(
                        (unitTemplate) => unitTemplate.factions.includes(force.faction) || unitTemplate.factions.includes('ALL')
                    );
                })
            )
        )
    );
    units = toSignal(this.units$);

    constructor(
        public forceService: ForceService,
        public dataLibrary: DataLibrary,
        public settingsService: SettingsService,
        public route: ActivatedRoute,
        public router: Router,
        public config: ArmyBuilderConfig,
        public forceUtils: ForceUtils,
        public translateService: TranslateService,
        public modal: Modal,
        public purchasingService: PurchasingService,
        public alertController: AlertController,
        public alertService: AlertService
    ) {}

    addUnit(unitTemplate: UnitTemplate, dataBucket?: any, redirect = true) {
        snapshot(combineLatest([this.force$, this.forceService.selectedPlatoonId$]), ([force, selectedPlatoonId]) => {
            dataBucket = {
                ...dataBucket,
                platoonId: dataBucket?.platoonId ?? selectedPlatoonId ?? 0
            };
            const newUnit = this.forceUtils.createUnitFromTemplate(unitTemplate, dataBucket);
            this.forceService.addUnit(newUnit, force);
            if (redirect) {
                this.router.navigate(['..'], { relativeTo: this.route });
            }
        });
    }

    duplicateUnit(unit: Unit) {
        snapshot(combineLatest([this.force$, this.forceService.selectedPlatoonId$]), ([force, selectedPlatoonId]) => {
            const newUnit = structuredClone(unit);
            const id = getUniqueUnitId(force);
            newUnit.id = id;
            this.forceService.addUnit(newUnit, force);
        });
    }

    async showPurchaseAlert(unitTemplate: UnitTemplate) {
        const unitName = unitTemplate.name[LANGUAGE];
        return new Promise((resolve) => {
            this.alertController
                .create({
                    header: this.translateService.instant('PURCHASES.UNIT_UNAVAILABLE.TITLE'),
                    message: this.translateService.instant('PURCHASES.UNIT_UNAVAILABLE.MESSAGE', { unitName }),
                    buttons: [
                        {
                            text: this.translateService.instant('GLOBAL.SUBSCRIBE'),
                            cssClass: 'alertButton',
                            handler: (_) => {
                                this.router.navigate(['subscribe']);
                                resolve(true);
                            }
                        },
                        {
                            text: this.translateService.instant('GLOBAL.CANCEL'),
                            cssClass: 'alertButton',
                            handler: (_) => resolve(true)
                        }
                    ]
                })
                .then((x) => x.present());
        });
    }
}
