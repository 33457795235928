import { computed, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Purchases } from '@revenuecat/purchases-js';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, SettingsService } from 'army-builder-shared';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    public userHasAccess$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles?.includes('app_subscriber') || l.user?.roles?.includes('app_subscriber_annual');
        })
    );
    public userHasAccess = toSignal(this.userHasAccess$);

    public newCustomer$ = this.settingsService.login$.pipe(
        map((l) => {
            return Object.keys(l.user?.entitlements ?? {}).length === 0;
        })
    );

    public userRoles$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles;
        })
    );

    public freeTrial$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles?.includes('app_free_trial');
        })
    );

    public expiry$ = this.settingsService.login$.pipe(
        map((l) => {
            const annualExpiry = new Date(l.user?.entitlements?.app_sub_annual?.expires_date).getTime() || 0;
            const monthlyExpiry = new Date(l.user?.entitlements?.app_sub?.expires_date).getTime() || 0;

            if (monthlyExpiry === 0 && annualExpiry === 0) {
                return null;
            }
            return new Date(Math.max(annualExpiry, monthlyExpiry));
        })
    );

    public hasExpired$ = this.expiry$.pipe(
        map((expiry) => {
            return expiry && expiry < new Date();
        })
    );

    currentSubscription = computed(() => {
        const getStoreFromSubscription = (subscription) => {
            const store = subscription.store;
            if (store === 'play_store') {
                return 'ANDROID';
            }
            if (store === 'app_store') {
                return 'IOS';
            }
            if (store === 'stripe') {
                return 'WEB';
            }
            if (store === 'promotional') {
                return 'PROMO';
            }
            return null;
        };

        let user = this.settingsService.login().user;
        let subscriptionKeys = Object.keys(user?.subscriptions);
        let subscriptions = subscriptionKeys
            .map((key) => {
                const sub = user?.subscriptions[key];
                const store = getStoreFromSubscription(sub);
                const expiryDate = new Date(sub.expires_date);
                const unsubscribeDate = sub.unsubscribe_detected_at ? new Date(sub.unsubscribe_detected_at) : null;
                return { store, expiryDate, unsubscribeDate };
            })
            .filter((sub) => sub.store !== null && sub.expiryDate >= new Date() && !sub.unsubscribeDate)
            .sort((a, b) => a.expiryDate.getTime() - b.expiryDate.getTime());

        if (subscriptions.length > 0) {
            return subscriptions[0];
        }
        return null;
    });

    subscriptionPlatform = computed(() => {
        return this.currentSubscription()?.store;
    });

    constructor(
        private http: HttpClientWithInFlightCache,
        private settingsService: SettingsService,
        private config: ArmyBuilderConfig
    ) {}

    async checkSubscriptionStatus() {
        try {
            console.log('Checking subscription status');
            const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
            console.log({ customerInfo });
            // access latest customerInfo
        } catch (e) {
            // Handle errors fetching customer info
        }
    }
}
