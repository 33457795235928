import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, shareReplay, switchMap, tap } from 'rxjs';
import { CustomLoader } from './custom-loader';
import { AppInjector } from '../app.injector';
import { ArmyBuilderConfig, RestDataService } from 'army-builder-shared';
import { Store } from '@ngrx/store';
import { Injector } from '@angular/core';

let translateServices: { [key: string]: TranslateService } = {};
export const getTranslateService = (gameId: string) => {
    if (translateServices) {
        if ((translateServices[gameId].currentLoader as CustomLoader).gameId !== gameId) {
            console.error('Wrong TranslateService!');
        }
        return translateServices[gameId];
    }
    return loadTranslateService(gameId);
};

const loadTranslateService = (gameId: string) => {
    if (translateServices[gameId]) {
        return translateServices[gameId];
    }
    translateServices[gameId] = AppInjector.get(TranslateService);
    let customLoader = AppInjector.get(CustomLoader);
    customLoader.gameId = gameId;
    translateServices[gameId].currentLoader = customLoader;

    return translateServices[gameId];
};

export const initTranslateService = (gameId: string) => {
    const translateService = loadTranslateService(gameId);
    return translateService.reloadLang('en');
};

export function waitForTranslateService<T>(gameId: string) {
    return function (source: Observable<T>): Observable<T> {
        return source.pipe(
            switchMap((val) => {
                const ts = getTranslateService(gameId);
                return ts.get('FORCES.MENU_TITLE').pipe(
                    shareReplay(1),
                    switchMap((_) => source)
                );
            })
        );
    };
}

export const getTranslateModuleConfig = (gameId: string) => {
    return {
        defaultLanguage: 'en',
        loader: {
            provide: TranslateLoader,
            deps: [Injector],
            useFactory: (injector: Injector) => {
                console.log('!!!!! Getting CustomLoader', gameId);
                let customLoader = new CustomLoader(gameId, injector);
                customLoader.gameId = gameId;
                return customLoader;
            },
            useExisting: false
        },
        isolate: true
    };
};
