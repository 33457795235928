import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLibraryData } from '../army-builder-store.module';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { GameEntityLibraryState } from '../army-builder-entity-store';
import { getDataFromApi } from './data-fetch';
import { UserService } from 'army-builder-shared';

@Component({
    selector: 'abs-entity-preload-modal',
    template: `
        <ng-container *ngLet="{ data: data$ | async } as props">
            <div class="fullPageOverlay" *ngIf="props.data?.showEntityPreload">
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>{{ 'GLOBAL.SETTINGS.ENTITY_DATA_LOADING.TITLE' | translate }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <p>
                            {{ 'GLOBAL.SETTINGS.ENTITY_DATA_LOADING.DESCRIPTION' | translate }}
                        </p>

                        <ng-container *ngFor="let gameId of gameIds">
                            <abs-entity-preload
                                *ngIf="props.data.entityTypeKeys[gameId]?.length > 0"
                                [gameId]="gameId"
                                [entityTypeKeys]="props.data.entityTypeKeys[gameId]"
                                (retry)="loadEntityData($event)"
                            ></abs-entity-preload>
                        </ng-container>
                    </ion-card-content>
                </ion-card>
            </div>
        </ng-container>
    `
})
export class EntityPreloadModalComponent {
    @Input()
    gameIds: string[];

    public data$ = this.userService.loggedIn$.pipe(
        distinctUntilChanged(),
        switchMap(() => this.store.select(getLibraryData)),
        map((lib) => {
            if (!lib?.data) {
                return { showEntityPreload: false, entityTypeKeys: {} };
            }
            let gameIds = Object.keys(lib.data);
            let loadingKeyCount = 0;

            let res = gameIds.reduce((agg, gameId) => {
                const gameEntities = lib.data[gameId] || {};

                const entityLoadData: GameEntityLibraryState<any>[] = Object.values(gameEntities) ?? [];
                let loadingCount = entityLoadData.filter((ge) => ge.status === 'LOADING' || ge.status === 'ERROR').length;
                if (lib.showPreloadModal === gameId || loadingCount > 0) {
                    loadingKeyCount++;
                    agg[gameId] = Object.keys(gameEntities);
                }
                return agg;
            }, {});

            return { showEntityPreload: lib.showPreloadModal || loadingKeyCount > 0, entityTypeKeys: res };
        }),
        debounceTime(100)
    );

    constructor(
        private store: Store,
        private userService: UserService
    ) {}

    loadEntityData({ gameId, entityKey }) {
        getDataFromApi(gameId, entityKey, { preferCache: false, fallbackToCache: false });
    }
}
