import { inject, Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    ActivationEnd,
    ActivationStart,
    NavigationEnd,
    ActivatedRoute,
    RoutesRecognized
} from '@angular/router';
import { map, filter, shareReplay, defaultIfEmpty, distinctUntilChanged, tap, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterService {
    route$: Observable<ActivatedRouteSnapshot> = this.router.events.pipe(
        filter((e) => e instanceof ActivationStart || e instanceof ActivationEnd),
        map((e) => {
            return (e as ActivationStart | ActivationEnd).snapshot;
        }),
        shareReplay(1)
    );

    // https://stackoverflow.com/questions/45737375/angular-activatedroute-data-returns-an-empty-object
    routeData$: Observable<any> = this.route$.pipe(
        map((e: ActivatedRouteSnapshot) => ({ ...e?.data })),
        distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c)),
        defaultIfEmpty({}),
        shareReplay(1)
    );

    primaryRouteParams$ = this.route$.pipe(
        filter((r) => r.outlet === 'primary'),
        map((r) => r.params)
    );

    selectedUnitId$ = this.primaryRouteParams$.pipe(map((p) => p.unitId));
    forceId$ = this.primaryRouteParams$.pipe(map((p) => p.forceId));

    route = inject(ActivatedRoute);

    constructor(private router: Router) {
        this.routeData$.subscribe((route) => {
            // Subscribing here ensures that the theme data loads based on route
            console.log('New route', route);
        });
    }
}
