import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { AlertService, Modal, ModalComponent } from 'army-builder-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Force, Platoon } from '../../models';

@Component({
    selector: 'abs-platoon-select',
    template: `<ion-button (click)="prev()" [disabled]="isFirst">&lt;</ion-button>
        <div class="label" (click)="editPlatoon()">
            <div class="platoonTitle">
                {{ platoon.name || ('FORCES.PLATOON_PREFIX' | translate) + (platoon.id + 1) }}
            </div>
        </div>

        <ion-button (click)="next()">&gt;</ion-button> `,
    styles: [
        `
            :host {
                display: flex;
                width: 100%;
                align-items: center;
            }

            .label {
                flex: 1 1 auto;
                text-align: center;
            }

            .platoonSelector {
                font-style: italic;
                font-size: 0.85em;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlatoonSelectComponent implements OnChanges {
    @Input({ required: true })
    platoon: Platoon;

    @Input({ required: true })
    force: Force;

    @Output()
    platoonChanged = new EventEmitter<number>();

    @ViewChild('editPlatoonTemplate')
    editPlatoonTemplate: TemplateRef<any>;

    isFirst: boolean = true;
    isLast: boolean = true;

    constructor(
        protected forceUtils: ForceUtils,
        protected forceService: ForceService,
        protected modal: Modal,
        protected router: Router,
        protected route: ActivatedRoute,
        protected alert: AlertService,
        protected translateService: TranslateService
    ) {}

    next() {
        this.router.navigate(['..', this.platoon.id + 1], { relativeTo: this.route });
    }

    prev() {
        this.router.navigate(['..', Math.max(this.platoon.id - 1, 0)], { relativeTo: this.route });
    }

    addNewPlatoon() {
        // if (this.force.validationErrors.find((e) => e.type === 'Error' && e.platoonId !== null && e.platoonId !== undefined)) {
        //     this.alert.showAlert(
        //         this.translateService.instant('FORCES.DIALOGS.ADD_PLATOON_VALIDATION_ERROR.TITLE'),
        //         this.translateService.instant('FORCES.DIALOGS.ADD_PLATOON_VALIDATION_ERROR.MESSAGE')
        //     );
        //     return;
        // }
        const newPlatoon: Platoon = { id: 0, name: '', validationErrors: [] };
        this.forceService.addPlatoon(this.force, newPlatoon);

        this.editPlatoon(this.platoon.id + 1);
    }

    editPlatoon(platoonId?: number) {
        console.log({ platoonId });
        if (platoonId === undefined) {
            this.router.navigate(['edit'], { relativeTo: this.route });
        } else {
            this.router.navigate(['..', platoonId, 'edit'], { relativeTo: this.route });
        }
    }

    ngOnChanges() {
        const i = this.force.platoons.findIndex((p) => p.id === this.platoon.id);
        this.isFirst = i === 0;
        this.isLast = i === this.force.platoons.length - 1;
    }
}
