import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArmyBuilderConfig } from 'army-builder-shared';

import { UserService } from '../userService';
import { PurchasingService } from './service/purchasing.service';

@Component({
    selector: 'purchase-handler',
    template: `
        <div class="wrap">
            <ion-spinner></ion-spinner>
            <p>{{ 'GLOBAL.PURCHASE_HANDLER.CHECKING_PURCHASES' | translate }}</p>
            <p *ngIf="success === undefined">Attempt {{ retryCount }} of {{ retryLimit }}</p>
            <p *ngIf="success === true">{{ 'GLOBAL.PURCHASE_HANDLER.FOUND' | translate }}</p>
            <p *ngIf="success === false">{{ 'GLOBAL.PURCHASE_HANDLER.NOT_FOUND' | translate }}</p>
        </div>
    `,
    styles: [
        `
            :host {
                background: white;
            }
            ion-spinner {
                transform: translate(-50%, -50%);
            }

            .wrap {
                height: 100vmin;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        `
    ]
})
export class PurchaseHandlerComponent implements OnInit {
    retryCount: number = 1;
    retryLimit = 5;
    success: boolean = undefined;

    constructor(
        private router: Router,
        private userService: UserService,
        private config: ArmyBuilderConfig,
        private route: ActivatedRoute,
        private purchaseService: PurchasingService
    ) {}

    async ngOnInit() {
        const url = this.config.apiBaseUrl + '/auth/refresh';

        const refreshAccessToken = async () => {
            const { user } = await this.userService.refreshAccessToken(url, null);

            const entitlements = Object.entries(user.entitlements as Object)
                .filter(([key, e]) => !e.expires_date || Date.parse(e.expires_date) > Date.now())
                .map(([key, e]) => key);
            if (this.userHasAccess(entitlements)) {
                this.success = true;
                setTimeout(() => {
                    this.router.navigateByUrl(this.config.purchaseHandlerRedirect, { replaceUrl: true });
                }, 3000);
            } else if (this.retryCount >= this.retryLimit) {
                this.success = false;
                setTimeout(() => {
                    this.router.navigateByUrl(this.config.purchaseHandlerRedirect, { replaceUrl: true });
                }, 3000);
            } else {
                this.retryCount++;
                setTimeout(refreshAccessToken, 2000);
            }
        };

        refreshAccessToken();
    }

    userHasAccess(roles: string[]): boolean {
        let requiredRoles = this.purchaseService.products.map((p) => p.key);
        return !!requiredRoles.find((r) => roles.includes(r));
    }
}
