import { inject, Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
    ArmyBuilderConfig,
    DebugService,
    getValueFromTranslationSync,
    HttpClientWithInFlightCache,
    Logger,
    Product,
    SettingsService,
    UserService
} from 'army-builder-shared';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, Subscription, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Capacitor } from '@capacitor/core';
import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases/ngx';

import {
    selectProducts,
    selectPurchases,
    selectStatus,
    selectRestoringPurchases,
    AddProduct,
    SetProductsStatus,
    AddPurchase,
    RemovePurchase
} from './state';

@Injectable()
export abstract class PurchasingService implements OnDestroy {
    platformKey: 'apple' | 'google' | 'stripe';

    protected platform = inject(Platform);
    protected store = inject(Store);
    protected debugService = inject(DebugService);
    protected purchases = inject(Purchases);
    protected http = inject(HttpClientWithInFlightCache);
    protected settingsService = inject(SettingsService);
    protected config = inject(ArmyBuilderConfig);
    protected userService = inject(UserService);

    abstract configurePlatform(userId: string): Promise<void>;
    abstract loadProductsForPlatform(): Promise<PurchasesPackage[]>;

    public products$: Observable<Product[]> = this.store.select(selectProducts);
    public products: Product[] = [];
    public purchases$: Observable<string[]> = this.store.select(selectPurchases);
    public status$: Observable<string[]> = this.store.select(selectStatus);
    public restoringPurchases$: Observable<boolean> = this.store.select(selectRestoringPurchases);

    protected userPurchases: string[];

    protected storageSub: Subscription;
    protected purchasesSub: Subscription;
    protected productsSub: Subscription;
    protected loginSub: Subscription;
    protected debug: Logger;

    constructor() {
        this.debug = this.debugService.getInstance('[Purchasing Service]');
        this.platform.ready().then(async () => {
            this.loginSub = this.settingsService.login$
                .pipe(
                    filter((l) => l.user),
                    map((l) => l.user),
                    distinctUntilChanged((before, after) => before.dbUserId === after.dbUserId)
                )
                .subscribe(async (user) => {
                    await this.configurePlatform(user.dbUserId);
                    const hydratedProducts = await this.loadProducts();
                    await this.restorePurchases();

                    // let entitlements = user.entitlements;
                    // for (let p of hydratedProducts) {
                    //     if (entitlements?.[p.key]?.expires_date > Date.now()) {
                    //         this.store.dispatch(AddPurchase({ productId: p.key }));
                    //     } else {
                    //         this.store.dispatch(RemovePurchase({ productId: p.key }));
                    //     }
                    // }
                });
        });

        this.purchasesSub = this.purchases$.subscribe((purchases) => {
            this.userPurchases = purchases;
        });

        this.products$.subscribe((products) => {
            this.products = products;
        });
    }

    ngOnDestroy(): void {
        this.storageSub.unsubscribe();
        this.purchasesSub.unsubscribe();
        this.productsSub.unsubscribe();
        if (this.loginSub) {
            this.loginSub.unsubscribe();
        }
    }

    async loadProducts() {
        const productsURL = this.config.apiBaseUrl + '/library/global/products?getAll=1';
        const apiProducts = await firstValueFrom(
            this.http.get(productsURL, { requiresLogin: true }).pipe(
                map((res) => res.items as Product[]),
                tap((p) => console.log(`configurePurchasing: ${p.length} products found`))
            )
        );

        const rcProducts = await this.loadProductsForPlatform();
        const res: Product[] = [];
        apiProducts.forEach((product) => {
            // Kind of hacky, but product.displayPrice is a translation, and needs to be a string to match with the price we get back from RC
            if (typeof product.displayPrice === 'object') {
                product.displayPrice = getValueFromTranslationSync(product.displayPrice);
            }

            const pkg = rcProducts?.find((p: PurchasesPackage) => p.identifier === product.key);
            const p = {
                ...product,
                displayPrice: pkg?.product?.priceString ?? product.displayPrice,
                package: pkg
            };
            res.push(p);
            this.store.dispatch(
                AddProduct({
                    product: p
                })
            );
        });

        return res;
    }

    async purchase(productId): Promise<boolean> {
        if (Capacitor.getPlatform() === 'web') {
            return;
        }
        this.store.dispatch(SetProductsStatus({ productId, status: 'loading' }));
        const products = await firstValueFrom(this.products$);
        const pkg = products.find((p) => p.key === productId).package;
        return this.purchases
            .purchasePackage(pkg)
            .then(async ({ productIdentifier, customerInfo }) => {
                this.debug.log(JSON.stringify({ productId, pkg, productIdentifier, customerInfo }));
                if (typeof customerInfo.entitlements.active[productId] !== 'undefined') {
                    this.store.dispatch(AddPurchase({ productId }));
                    const url = this.config.apiBaseUrl + '/auth/refresh';
                    const { user } = await this.userService.refreshAccessToken(url, null);
                    this.debug.log(`${productId} added`);
                    return true;
                }
            })
            .catch((err) => {
                // Error making purchase
                console.log(err);
                this.debug.log('Error during purchase: ' + JSON.stringify(err));
                return false;
            });
    }

    refreshUserPurchases() {
        this.log('refreshUserPurchases');
        this.purchases.getCustomerInfo().then((customerInfo) => {
            this.log('refreshUserPurchases', { customerInfo });
            const productIds = this.products.map((p) => p.key);
            const entitlements = Object.values(customerInfo.entitlements.active).map((e) => e.identifier);
            productIds.forEach((productId) => {
                if (this.userPurchases.includes(productId) && !entitlements.includes(productId)) {
                    this.log('Removing purchase from app state: ', productId);
                    this.store.dispatch(RemovePurchase({ productId }));
                } else if (!this.userPurchases.includes(productId) && entitlements.includes(productId)) {
                    this.log('Adding purchase to app state: ', productId);
                    this.store.dispatch(AddPurchase({ productId }));
                }
            });
            this.userPurchases = entitlements;
        });
    }

    isOwned(productId: string): boolean {
        const purchaseKey = productId.toLowerCase();
        if (purchaseKey === 'core') {
            return true;
        }

        // if (!this.platform.is('cordova')) {
        //     return true;
        // }

        return this.userPurchases.includes('all_access') || this.userPurchases.includes(purchaseKey);
        return false;
    }

    // private addOwnedPurchases() {
    //     this.store.dispatch(PurchasesLoading());
    //     this.purchases.syncPurchases();

    //     this.purchases.restorePurchases();
    //     this.refreshUserPurchases();
    //     this.store.dispatch(PurchasesLoaded());
    // }

    async restorePurchases() {
        // console.log('restorePurchases', { configured: this.configured });
        // if (!this.configured) {
        //     await this.configurePurchasing();
        // }
        // this.addOwnedPurchases();
        await this.purchases.restorePurchases();
        this.refreshUserPurchases();
    }

    isItemAvailable(item: any): boolean {
        if (!item?.packs) {
            return false;
        }
        for (const pack of item.packs) {
            if (this.isOwned(pack)) {
                return true;
            }
        }
        return false;
    }

    log(...args: any[]) {
        this.debug.log(...args);
        console.log(...args);
    }
}
