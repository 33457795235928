import { Component, inject, Optional } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { ArmyBuilderConfig } from '../../../global/config';
import { AlertService } from '../../../global/alert/alert';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { getUniqueUnitId, snapshot } from '../../../global/utils';
import { ForceService } from '../../force.service';
import { Force, Unit } from '../../models';
import { ForceUtils } from '../../force.utils';
import { SelectSyncStatus } from '../../../global/data';
import { Modal } from '../../../global/modal';
import { BattleService } from '../../../battle';
import { DataLibrary } from '../../../global';
import { ToastService } from '../../../global/toast/toast';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'abs-force',
    template: `
        <abs-page-layout
            *ngIf="forceService.ready() && force() as force"
            (titleClicked)="renameForce(force)"
            [title]="title$ | async"
            [subTitle]="force.cost + ('GLOBAL.PTS' | translate)"
        >
            <div class="buttons">
                <ion-button class="addUnit" [routerLink]="getAddUnitLink(force)">{{ 'FORCES.BUTTONS.ADD_UNIT' | translate }}</ion-button>
            </div>

            <ng-template #unitTemplate let-unit="item">
                <div class="itemName">
                    {{ unit | unitName }}
                </div>

                <div class="itemCost">{{ unit | unitCost }}{{ 'GLOBAL.PTS' | translate }}</div>
            </ng-template>

            <abs-list
                [items]="units$ | async"
                (deleteItem)="deleteUnit($event.item)"
                [showDelete]="true"
                [getRouterLink]="getUnitLink(force)"
                [template]="unitTemplate"
            ></abs-list>
        </abs-page-layout>
    `
})
export class ForcePage {
    public route = inject(ActivatedRoute);
    public forceService = inject(ForceService);
    public forceUtils = inject(ForceUtils);
    public alertService = inject(AlertService);
    public inputAlert = inject(InputAlert);
    public router = inject(Router);
    public config = inject(ArmyBuilderConfig);
    public dataLibrary = inject(DataLibrary);
    public translateService = inject(TranslateService);
    public popoverController = inject(PopoverController);
    public store = inject(Store<any>);
    public modalService = inject(Modal);
    public toastService = inject(ToastService);

    shared$ = this.route.data.pipe(map((d) => !!d.shared));
    shared = this.forceService.shared;

    force$ = this.forceService.force$;
    force = this.forceService.force;

    selectedPlatoonId$ = this.route.params.pipe(map((p) => parseInt(p.platoonId) || 0));
    selectedPlatoonId = toSignal(this.selectedPlatoonId$);
    selectedPlatoon$ = combineLatest([this.force$, this.selectedPlatoonId$]).pipe(
        map(([force, platoonId]) => {
            return force.platoons.find((p) => p.id === platoonId);
        }),
        filter((p) => !!p)
    );
    selectedPlatoon = toSignal(this.selectedPlatoon$);

    syncStatus$ = this.force$.pipe(switchMap((f) => this.store.select(SelectSyncStatus, { entityType: 'forces', id: f.id })));
    units$ = this.forceService.units$;
    unitsInPlatoon$ = combineLatest([this.units$, this.selectedPlatoonId$]).pipe(
        map(([units, platoonId]) => units.filter((u: Unit) => u.platoonId === platoonId))
    );

    title$ = combineLatest([
        this.forceService.force$,
        this.translateService.get('GLOBAL.TAP_TO_RENAME'),
        this.translateService.get('FORCES.DEFAULT_FORCE_NAME')
    ]).pipe(
        filter((x) => !!x[0]),
        map(([force, tapToRename, defaultForceName]) => (force.name === defaultForceName || !force.name ? tapToRename : force.name)),
        distinctUntilChanged()
    );

    constructor(@Optional() public battleService: BattleService) {
        console.log('ForcePage constructor');
    }

    renameForce(force: Force) {
        snapshot(
            combineLatest([
                this.translateService.get('FORCES.DIALOGS.RENAME_FORCE.TITLE'),
                this.translateService.get('FORCES.DIALOGS.RENAME_FORCE.MESSAGE')
            ]),
            ([title, message]) => {
                this.inputAlert.show(title, message, force.name, 'text', true).then((name) => {
                    if (!name) {
                        return;
                    }

                    this.forceService.update({
                        id: force.id,
                        name
                    });
                });
            }
        );
    }

    deleteUnit(unit: Unit) {
        const force = this.force();
        this.forceService.deleteUnit(force, unit);
        this.toastService
            .showToast('', 'Unit Deleted', {
                color: 'success',
                buttons: [
                    {
                        text: 'Undo',
                        role: 'cancel'
                    }
                ]
            })
            .then((res) => {
                if (res.role === 'cancel') {
                    this.forceService.update(force);
                }
            });
    }

    getAddUnitLink(force) {
        return [this.config.partitionUnitsByFaction ? 'addUnitByFaction' : 'addUnit'];
    }

    getUnitLink(force) {
        return (unit) => ['unit', unit.id];
    }

    duplicateUnit(e: any, unit: Unit) {
        e.stopPropagation();
        e.preventDefault();
        const force = this.force();
        const newUnit = structuredClone(unit);
        const id = getUniqueUnitId(force);
        newUnit.id = id;
        this.forceService.addUnit(newUnit, force);
    }
}
