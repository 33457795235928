import { Unit } from '../../models';
import { ForceState } from '../state';

export const addUnitReducer = (state: ForceState, { forceId, unit }: { forceId: string; unit: Unit }): ForceState => ({
    ...state,
    forces: state.forces.map((f) => {
        if (f.id !== forceId) {
            return f;
        }
        return {
            ...f,
            units: [...f.units, unit]
        };
    })
});
