import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'wl-menu',
    template: `
        <ion-list>
            <ng-container *ngFor="let item of menuItems; let i = index">
                @if (!item.requiresLogin || isLoggedIn) {
                    <ion-item-divider *ngIf="item.children">
                        {{ item.title | translate }}
                    </ion-item-divider>
                    <wl-menu *ngIf="item.children" [isLoggedIn]="isLoggedIn" [menuItems]="item.children" [mode]="mode"> </wl-menu>
                    <ion-menu-toggle auto-hide="false" *ngIf="!item.children && mode === 'root'">
                        <ng-template [ngTemplateOutlet]="menuTemplate"></ng-template>
                    </ion-menu-toggle>

                    <ng-container *ngIf="mode !== 'root'">
                        <ng-template [ngTemplateOutlet]="menuTemplate"></ng-template>
                    </ng-container>

                    <ng-template #menuTemplate>
                        <ion-item
                            *ngIf="item.url"
                            [class.alt]="i % 2 === 0"
                            [routerDirection]="mode"
                            [routerLink]="[item.url]"
                            lines="full"
                        >
                            <ion-icon slot="start" [name]="item.icon"></ion-icon>
                            <ion-label>
                                {{ item.title | translate }}
                            </ion-label>
                        </ion-item>

                        <ion-item
                            *ngIf="item.onClick"
                            button="true"
                            [class.alt]="i % 2 === 0"
                            [routerDirection]="mode"
                            (click)="item.onClick()"
                            lines="full"
                        >
                            <ion-icon slot="start" [name]="item.icon"></ion-icon>
                            <ion-label>
                                {{ item.title | translate }}
                            </ion-label>
                        </ion-item>
                    </ng-template>
                }
            </ng-container>
        </ion-list>
    `,
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {
    @Input()
    menuItems: MenuItem[];

    @Input()
    isLoggedIn = false;

    @Input()
    mode: 'forward' | 'root' = 'forward';

    ngOnChanges() {
        // this.menuItems = this.menuItems.filter((x) => this.isLoggedIn || !x.requiresLogin);
    }
}

export interface MenuItem {
    title: string;
    icon?: string;
    url?: string;
    onClick?: () => void;
    children?: MenuItem[];
    requiresLogin?: boolean;
    route?: string;
}
