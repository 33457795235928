import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseAppComponent, CustomLoader, PurchasingService, SelectTranslations } from 'army-builder-shared';

import { environment } from '../environments/environment';
import pkg from '../../package.json';

import { MenuItem } from './global/menu/menu.component';
import { WarlordUserService } from './settings/user/user.service';
import { SubscriptionService } from './subscription/subscription.service';
import { GAME_IDS } from './game-id.token';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-root',
    template: `
        <ion-app
            *ngLet="{
                otherClientActive: otherClientActive$ | async,
                hasAccess: subscriptionService.userHasAccess$ | async,
                appPages: appPages$ | async,
                translationState: translationState$ | async,
                isLoggedIn: isLoggedIn$ | async
            } as props"
            [class]="platformDescription"
        >
            <ion-split-pane contentId="mainRouterOutlet">
                <ion-menu type="overlay" side="end" contentId="mainRouterOutlet" *ngIf="props.appPages">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>{{ 'GLOBAL.MENU' | translate }}</ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <wl-menu [menuItems]="props.appPages" [isLoggedIn]="props.isLoggedIn" [mode]="'root'"></wl-menu>
                    </ion-content>
                    <ion-footer>
                        <a href="#" (click)="reportProblem($event)" class="support">{{ 'GLOBAL.SUPPORT.LINK' | translate }}</a>
                        |
                        <a href="mailto:warlord@maloric.com" class="contact">{{ 'GLOBAL.MENU_ITEMS.CONTACT' | translate }}</a>
                        |
                        <span class="version">{{ appVersion }}</span>
                    </ion-footer>
                </ion-menu>
                <ion-router-outlet id="mainRouterOutlet"></ion-router-outlet>
            </ion-split-pane>

            <div class="fullPageOverlay" *ngIf="supported === false">
                <ion-card>
                    <h1>{{ 'GLOBAL.UNSUPPORTED_CLIENT.TITLE' | translate }}</h1>
                    <p>
                        {{
                            'GLOBAL.UNSUPPORTED_CLIENT.MESSAGE'
                                | translate
                                    : {
                                          minClientVersion: this.minClientVersion,
                                          currentClientVersion: this.currentClientVersion
                                      }
                        }}
                    </p>
                </ion-card>
            </div>

            <div class="useHere" *ngIf="props.otherClientActive">
                <ion-card>
                    <h1>{{ 'GLOBAL.UNSUPPORTED_CLIENT.TITLE' | translate }}</h1>
                    <p>
                        {{
                            'GLOBAL.UNSUPPORTED_CLIENT.MESSAGE'
                                | translate
                                    : {
                                          minClientVersion: this.minClientVersion,
                                          currentClientVersion: this.currentClientVersion
                                      }
                        }}
                    </p>
                </ion-card>
            </div>

            <abs-entity-preload-modal [gameIds]="gameIds"></abs-entity-preload-modal>
            <abs-translation-preload-modal [gameIds]="gameIds" [language]="language"></abs-translation-preload-modal>
        </ion-app>
    `,
    styles: [
        `
            .logo {
                max-width: 200px;
            }

            ion-footer {
                padding: 5px;
                background: #5b5b5b;
                color: white;
            }

            ion-footer a {
                color: white;
            }

            .version,
            .contact,
            .support {
                padding: 0 10px;
            }
        `
    ]
})
export class AppComponent extends BaseAppComponent {
    affId = environment.affiliateId;
    appVersion: string = pkg.version;
    clearDataBeforeVersion: string = pkg.clearDataBeforeVersion;
    supported = null;
    minClientVersion = null;
    currentClientVersion = null;
    otherClientActive$ = new BehaviorSubject<boolean>(false);
    translationState$ = this.store.select(SelectTranslations);
    isLoggedIn$ = this.userService.loggedIn$;

    gameIds = ['global', ...Object.values(GAME_IDS)];
    platformDescription = Capacitor.getPlatform();

    MENU_GAMES: MenuItem[] = [
        // {
        //     title: 'GLOBAL.MENU_ITEMS.BOLT_ACTION',
        //     children: [
        //         { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/bolt-action/forces' },
        //         { title: 'GLOBAL.MENU_ITEMS.LIBRARY', icon: 'list', url: '/bolt-action/library' },
        //         { title: 'GLOBAL.MENU_ITEMS.SETTINGS', icon: 'cog', url: '/bolt-action/settings' }
        //     ],
        //     requiresLogin: true
        // },
        {
            title: 'GLOBAL.MENU_ITEMS.BOLT_ACTION_3E',
            children: [
                { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/bolt-action-3e/forces' },
                { title: 'GLOBAL.MENU_ITEMS.LIBRARY', icon: 'list', url: '/bolt-action-3e/library' },
                { title: 'GLOBAL.MENU_ITEMS.SETTINGS', icon: 'cog', url: '/bolt-action-3e/settings' }
            ],
            requiresLogin: true
        },
        {
            title: 'GLOBAL.MENU_ITEMS.BLACK_SEAS',
            children: [
                { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/black-seas/forces' },
                { title: 'GLOBAL.MENU_ITEMS.BATTLE_TRACKER', icon: 'list', url: '/black-seas/battle' },
                { title: 'GLOBAL.MENU_ITEMS.SETTINGS', icon: 'cog', url: '/black-seas/settings' }
            ],
            requiresLogin: true
        },
        {
            title: 'GLOBAL.MENU_ITEMS.BLOOD_RED_SKIES',
            children: [
                { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/blood-red-skies/forces' },
                { title: 'GLOBAL.MENU_ITEMS.LIBRARY', icon: 'list', url: '/blood-red-skies/library' },
                { title: 'GLOBAL.MENU_ITEMS.SETTINGS', icon: 'cog', url: '/blood-red-skies/settings' }
            ],
            requiresLogin: true
        }
    ];

    MENU_OTHER: MenuItem[] = [
        {
            title: 'GLOBAL.MENU_ITEMS.SETTINGS',
            icon: 'cog',
            url: '/settings',
            requiresLogin: true
        },
        {
            title: 'GLOBAL.MENU_ITEMS.SUPPORT_TICKETS',
            icon: 'flag-outline',
            url: '/support',
            requiresLogin: true
        },
        {
            title: 'GLOBAL.MENU_ITEMS.PATCH_NOTES',
            icon: 'list',
            url: '/pages/patch-notes'
        },
        {
            title: 'GLOBAL.MENU_ITEMS.KNOWN_ISSUES',
            icon: 'bug-outline',
            url: '/pages/known-issues'
        },
        {
            title: 'GLOBAL.MENU_ITEMS.FAQ',
            icon: 'help-circle-outline',
            url: '/pages/faq'
        },
        {
            title: 'GLOBAL.MENU_ITEMS.EULA',
            icon: 'reader-outline',
            url: '/eula'
        }
    ];

    public appPages$: Observable<MenuItem[]> = combineLatest([
        this.userService.isAdmin$,
        this.subscriptionService.userHasAccess$,
        this.userService.loggedIn$
    ]).pipe(
        // select(LoginSelector),
        map(([isAdmin, isSubscriber, isLoggedIn]) => {
            let menuItems: MenuItem[] = [...this.MENU_OTHER];

            if (isSubscriber) {
                menuItems = [
                    ...this.MENU_GAMES,
                    {
                        title: 'GLOBAL.MENU_ITEMS.OTHER',
                        children: this.MENU_OTHER
                    }
                ];
            }

            if (isAdmin) {
                menuItems.push({
                    title: 'GLOBAL.MENU_ITEMS.ADMIN',
                    icon: 'create',
                    url: '/admin'
                });

                // menuItems[0].children?.push({
                //     title: 'GLOBAL.MENU_ITEMS.COLLECTION_MANAGER',
                //     icon: 'list',
                //     url: '/bolt-action-3e/collection-manager'
                // });
            }

            if (isLoggedIn) {
                menuItems.push({
                    title: 'GLOBAL.LOGIN.BUTTONS.LOGOUT',
                    icon: 'log-out-outline',
                    onClick: () => this.userService.logout()
                });
            }
            return menuItems;
        })
    );

    constructor(
        protected userService: WarlordUserService,
        protected menu: MenuController,
        protected subscriptionService: SubscriptionService,
        protected purchaseService: PurchasingService,
        protected customLoader: CustomLoader
    ) {
        super();
        this.userService.loggedIn$.subscribe((loggedIn) => {
            this.menu.enable(loggedIn);
        });
    }

    reportProblem(e?) {
        if (e) {
            e.preventDefault();
        }
        this.supportService.showModal();
    }

    init() {}
}
